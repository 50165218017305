import 'chart.js/auto';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { Breadcrumb, Card, Flex } from 'antd';

import { useState } from 'react';
import { FlowAnalyticsFilter } from '@/types.ts';
import { AnalyticsLineChart } from '@/features/analytics/component/AnalyticsLineChart.tsx';
import { useGetAnalytics } from '@/features/analytics/hooks/api.ts';
import { useGetDevice } from '@/features/devices';
import { makeDeviceBreadcrumbs } from '@/utils/helper.ts';
import { AnalyticsFilter } from '@/features/analytics/component/AnalyticsFilter.tsx';
import dayjs from 'dayjs';

export const ViewAnalytics = () => {
    const { t: breadcrumbs } = useTranslation('breadcrumbs');
    const { t } = useTranslation('common');
    const { id } = useParams();
    const location = useLocation();

    const { data: device } = useGetDevice(parseInt(id!));
    const backUrl = (location.state && location.state.prevLocation) || `${ROUTES.DEVICE_EDIT}/${id}`;

    const breadcrumbItems = [
        {
            title: <Link to={ROUTES.DASHBOARD}>{t('breadcrumbs:home')}</Link>,
        },
        {
            title: <Link to={backUrl}>{makeDeviceBreadcrumbs(device?.data)}</Link>,
        },
        {
            title: breadcrumbs('breadcrumbs:analytics'),
        },
    ];

    const end = dayjs();
    const start = end.clone().subtract(10, 'days');
    const [filter, setFilter] = useState<FlowAnalyticsFilter>({ date_from: start, date_to: end });

    const handleFilter = (dates?: FlowAnalyticsFilter) => {
        setFilter(dates ?? { date_from: start, date_to: end });
    };

    const { data: analytics, isLoading } = useGetAnalytics(parseInt(id!), filter);

    return (
        <>
            <div className="flex justify-between mb-4">
                <Breadcrumb items={breadcrumbItems} />
            </div>

            <AnalyticsFilter currentValue={filter} handleFilters={handleFilter} />

            <Card loading={isLoading} classNames={{ body: 'p-[16px] md:p-[24px]' }}>
                <Flex className="mb-[20px]" justify="space-between" align="center">
                    <span className="text-[16px] md:text-[18px] leading-6 font-medium">{t('use_water')}</span>
                    <div>
                        <span className="text-[16px] leading-4 font-normal">{`${t('total')}: `}</span>
                        <span className="text-[18px] leading-6 font-medium">{`${analytics?.data?.sum} л`}</span>
                    </div>
                </Flex>
                <div className="grid grid-cols-2 gap-x-4">
                    <AnalyticsLineChart analytics={analytics} filter={filter} translate={t} />
                </div>
            </Card>
        </>
    );
};
