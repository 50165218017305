import { Tooltip, Modal, Card, Divider, Badge, Flex } from 'antd';
import type { TableProps } from 'antd';
import {
	DeleteOutlined,
	EditOutlined,
	ExclamationCircleFilled,
	LineChartOutlined,
	LinkOutlined,
	SettingOutlined,
	WarningOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Device, DevicesTableFilter, DevicesTableSortBy, SortOrder } from '@/types.ts';
import { ROUTES } from '@/features/router';
import { useState } from 'react';
import { useMessageApiContext } from '@/features/message-api/context';
import { TableLocale } from 'antd/es/table/interface';
import { DevicesFilter } from './DevicesFilter.tsx';
import axios from 'axios';
import { useDeleteDevice, useDevices } from '@/features/devices';
import { ERRORS } from '@/utils/constants.ts';
import { useRBAC } from '@/features/auth';
import { AdaptiveTable } from '@/features/adaptive/components/AdaptiveTable.tsx';
import { useAdaptiveContext } from '@/features/adaptive/context/Context.ts';

type ShowDeleteProps = {
    id: number;
};

export const DevicesTable = () => {
	const [page, setPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(10);
	const [filter, setFilter] = useState<DevicesTableFilter>();
	const [sortBy, setSortBy] = useState<DevicesTableSortBy>();

	const { mutateAsync } = useDeleteDevice();
	const { data, isLoading } = useDevices(page, perPage, filter, sortBy);
	const { t } = useTranslation('devices');
	const navigate = useNavigate();
	const { confirm } = Modal;
	const { messageApi } = useMessageApiContext();
	const { isClient, isAdmin } = useRBAC();
	const { isTabletOrMobile } = useAdaptiveContext();

	const showDeleteConfirm = ({ id }: ShowDeleteProps) => {
		confirm({
			title: t('delete_confirmation'),
			// content: <span className="text-lg">{id}</span>,
			icon: <ExclamationCircleFilled />,
			okText: t('common:delete_ok'),
			okType: 'danger',
			cancelText: t('common:delete_cancel'),
			onOk: async () => {
				try {
					await mutateAsync(id);
					messageApi.open({
						type: 'success',
						content: t('common:message_success')
					});
				} catch (e) {
					if (axios.isAxiosError(e)) {
						let message = t('common:message_error');
						if (e.response?.data.status === ERRORS.CONSTRAINT_ERROR) {
							message = t('common:message_constraint_error');
						}

						messageApi.open({
							type: 'error',
							content: message
						});
					}
				}
			},
			onCancel() {}
		});
	};

	const goToDevicePage = (record: Device) => () => {
		//navigate(`${ROUTES.DEVICE_IFRAME}/${record.id}`);
		const link = record.uri.replace('https://', `https://${record.login}:${record.password}@`);
		const w = window.open(link, '_blank');
		if (w) {
			w.focus();
		}
	};

	const columns: TableProps<Device>['columns'] = [
		{
			title: t('user'),
			dataIndex: ['user', 'counterparty'],
			key: 'user',
			render: (text: string) => <a>{text}</a>
		},
		{
			title: t('system'),
			dataIndex: ['device_model', 'title'],
			key: 'device_model'
		},
		{
			title: t('state'),
			key: 'state',
			dataIndex: 'state',
			render: (_, { state }) => (state ? <Badge status="success" text={t('online')} /> : <Badge status="error" text={t('offline')} />)
		},
		{
			title: t('serial_number'),
			dataIndex: 'serial_number',
			key: 'serial_number',
			sorter: true
		},
		{
			title: t('country'),
			dataIndex: ['country', 'title'],
			key: 'country',
			sorter: true
		},
		{
			title: t('region'),
			dataIndex: ['region', 'title'],
			key: 'region',
			sorter: true
		},
		{
			title: t('address'),
			dataIndex: 'address',
			key: 'address',
			sorter: true
		},
		{
			title: t('dealer'),
			dataIndex: ['user', 'referrer', 'counterparty'],
			key: 'dealer',
			sorter: true
		},
		{
			title: t('common:actions'),
			key: 'action',
			render: (_, record) => (
				<Flex align="center" wrap={true}>
					{isTabletOrMobile && (
						<>
							<Tooltip placement="top" title={t('go_to_device')}>
								<LinkOutlined onClick={goToDevicePage(record)} style={{ color: '#0c2cc7', fontSize: 'clamp(1rem, 5vw, 20px)' }} />
							</Tooltip>
							<Divider type="vertical" />
						</>
					)}
					<Tooltip placement="top" title={t('analytics')}>
						<LineChartOutlined
							onClick={(e) => {
								e.stopPropagation();
								navigate(`${ROUTES.ANALYTICS_VIEW}/${record.id}`);
							}}
							style={{ color: '#f57e02', fontSize: 'clamp(1rem, 5vw, 20px)' }}
						/>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip placement="top" title={t('accidents')}>
						<WarningOutlined
							onClick={(e) => {
								e.stopPropagation();
								navigate(`${ROUTES.DEVICE_ACCIDENTS}/${record.id}`);
							}}
							style={{ color: '#c2980f', fontSize: 'clamp(1rem, 5vw, 20px)' }}
						/>
					</Tooltip>
					{!isClient() && (
						<>
							<Divider type="vertical" />
							<Tooltip placement="top" title={t('notification_settings')}>
								<SettingOutlined
									onClick={(e) => {
										e.stopPropagation();
										navigate(`${ROUTES.DEVICE_NOTIFICATIONS}/${record.id}`);
									}}
									style={{ color: '#0c2cc7', fontSize: 'clamp(1rem, 5vw, 20px)' }}
								/>
							</Tooltip>
							<Divider type="vertical" />
							<Tooltip placement="top" title={t('common:tooltip_edit')}>
								<EditOutlined
									onClick={(e) => {
										e.stopPropagation();
										navigate(`${ROUTES.DEVICE_EDIT}/${record.id}`);
									}}
									style={{ color: '#389E0D', fontSize: 'clamp(1rem, 5vw, 20px)' }}
								/>
							</Tooltip>
							{isAdmin() && (
								<>
									<Divider type="vertical" />
									<Tooltip placement="top" title={t('common:tooltip_delete')}>
										<DeleteOutlined
											onClick={(e) => {
												e.stopPropagation();
												showDeleteConfirm(record);
											}}
											style={{ color: '#CF1322', fontSize: 'clamp(1rem, 5vw, 20px)' }}
										/>
									</Tooltip>
								</>
							)}
						</>
					)}
				</Flex>
			)
		}
	];

	const tableLocale: TableLocale = {
		emptyText: <div className={'py-[74px]'}> {t('common:emptyText')}</div>
	};

	const handleTableChange: TableProps<Device>['onChange'] = (pagination, _filters, sorter) => {
		if (pagination?.current) {
			setPage(pagination.current);
		}

		if (pagination?.pageSize) {
			setPerPage(pagination.pageSize);
		}

		if (!Array.isArray(sorter)) {
			let sortOrder: SortOrder;
			if (sorter.order === 'descend') {
				sortOrder = 'desc';
			} else if (sorter.order === 'ascend') {
				sortOrder = 'asc';
			}

			setSortBy({});
			if (sorter.columnKey === 'serial_number') {
				setSortBy((prevState) => {
					const newState = { ...prevState };
					newState.serial_number = sortOrder;
					return newState;
				});
			}
			if (sorter.columnKey === 'country') {
				setSortBy((prevState) => {
					const newState = { ...prevState };
					newState.country = sortOrder;
					return newState;
				});
			}
			if (sorter.columnKey === 'region') {
				setSortBy((prevState) => {
					const newState = { ...prevState };
					newState.region = sortOrder;
					return newState;
				});
			}
			if (sorter.columnKey === 'address') {
				setSortBy((prevState) => {
					const newState = { ...prevState };
					newState.address = sortOrder;
					return newState;
				});
			}
			if (sorter.columnKey === 'dealer') {
				setSortBy((prevState) => {
					const newState = { ...prevState };
					newState.diller = sortOrder;
					return newState;
				});
			}
		}
	};

	const handleSearch = (values?: DevicesTableFilter) => {
		if (values) {
			setFilter(values);
			setPage(1);
		}
	};

	return (
		<>
			<DevicesFilter handleSearch={handleSearch} />
			<Card bordered={false} className={'shadow-none overflow-auto'}>
				<AdaptiveTable
					loading={isLoading}
					columns={columns}
					dataSource={data?.data.paginated}
					rowKey="id"
					onChange={handleTableChange}
					pagination={{
						current: page,
						total: data?.data.meta?.total,
						defaultPageSize: perPage,
						showSizeChanger: true,
						showTotal: (total, range) =>
							t('common:paginationTotal', {
								range1: range[0],
								range2: range[1],
								total: total
							})
					}}
					locale={tableLocale}
					onRow={(record) => {
						return {
							onClick: goToDevicePage(record)
						};
					}}
					rowClassName={'cursor-pointer'}
					collapseLabel={(record) => (
						<Flex justify="space-between" align="center">
							<span>{t('user')}</span>
							<span>{record.user.counterparty}</span>
						</Flex>
					)}
					omitColumns={['user']}
				/>
			</Card>
		</>
	);
};
