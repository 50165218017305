import React, { useEffect } from 'react';
import { Form, Input, Button, Card, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeviceModelRequest } from '@/types.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessageApiContext } from '@/features/message-api/context';
import { ROUTES } from '@/features/router';
import { useGetDeviceModel, useUpdateDeviceModel } from '@/features/device-models';
import { useGetRevisions } from '@/features/device-models/hooks/api.ts';

export const DeviceModelEditForm: React.FC = () => {
	const { t } = useTranslation('device_models');
	const { id } = useParams();
	const { data, isLoading, isSuccess } = useGetDeviceModel(parseInt(id!));
	const { mutateAsync, isPending } = useUpdateDeviceModel(parseInt(id!));
	const { data:revisions, isLoading:revisionIsLoading } = useGetRevisions();
	const { messageApi } = useMessageApiContext();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	useEffect(() => {
		if (isSuccess) {
			form.setFieldsValue({
				title: data.data.title,
				revision: data.data.revision
			});
		}
	}, [isSuccess, data, form]);

	const onSubmit = async (values: DeviceModelRequest) => {
		try {
			await mutateAsync({
				title: values.title,
				revision: values.revision
			});
			messageApi.open({
				type: 'success',
				content: t('common:message_success')
			});
			navigate(ROUTES.DEVICE_MODELS);
		} catch (_) {
			messageApi.open({
				type: 'error',
				content: t('common:message_error')
			});
		}
	};

	return (
		<Card title={t('edit_device_model')} loading={isLoading}>
			<Form layout="vertical" form={form} onFinish={onSubmit} requiredMark={false}>
				<Form.Item<DeviceModelRequest> name="title" label={t('device_model')} rules={[{ required: true, message: t('common:rule_required') }]}>
					<Input placeholder={t('device_model')} />
				</Form.Item>
				<Form.Item<DeviceModelRequest>
					name="revision"
					label={t('revision')}
					rules={[{ required: true, message: t('common:rule_required') }]}
				>
					<Select options={revisions?.data} loading={revisionIsLoading} />
				</Form.Item>
				<Button type="primary" htmlType="submit" loading={isPending}>
					{t('common:submit')}
				</Button>
				<Button
					onClick={() => {
						navigate(ROUTES.DEVICE_MODELS);
					}}
					htmlType="button"
					className="ml-2"
				>
					{t('common:cancel')}
				</Button>
			</Form>
		</Card>
	);
};
