export const API_ROUTES = {
	AUTH_LOGIN: {
		URL: '/auth/login'
	},
	AUTH_LOGOUT: {
		URL: '/auth/logout'
	},
	PROFILE: {
		URL: '/api/me',
		KEY: 'profile'
	},
	ROLES: {
		URL: '/api/roles',
		KEY: 'roles'
	},
	REFERRERS: {
		URL: 'api/helpers/referral-list',
		KEY: 'referrers'
	},
	USERS: {
		URL: '/api/users',
		KEY: 'users'
	},
	COUNTRIES: {
		URL: '/api/countries',
		KEY: 'countries'
	},
	REGIONS: {
		URL: '/api/regions',
		KEY: 'regions'
	},
	DEVICE_MODELS: {
		URL: '/api/device-model',
		KEY: 'device-models'
	},
	DEVICES: {
		URL: '/api/device',
		KEY: 'devices'
	},
	DEVICE_TEST: {
		URL: 'api/helpers/device-test',
		KEY: 'device-test'
	},
	DEVICE_EVENTS: {
		URL: '/api/device-events',
		KEY: 'device'
	},
	DEVICE_HELPERS: {
		URL: '/api/helpers/event-types',
		KEY: 'helpers'
	},
	COUNT_DATA: {
		URL: 'api/helpers/count-data',
		KEY: 'count-data'
	},
	LANGUAGE: {
		URL: 'api/helpers/set-lang',
		KEY: 'language'
	},
	NOTIFICATION_INFO: {
		URL: 'api/notification-info',
		KEY: 'notification-info'
	},
	FLOW_ANALYTICS: {
		URL: 'api/flow-analytics',
		KEY: 'flow-analytics'
	},
	MIKROTIK_SETTINGS: {
		URL: 'api/helpers/mikrotik-settings',
		KEY: 'mikrotik-settings'
	},
	OSMO_DEVICES: {
		URL: 'api/device-arduino',
		KEY: 'arduino-devices'
	},
	OSMO_DEVICE_MAINTENANCE: {
		URL: 'api/helpers/provide-maintenance',
		KEY: 'provide-maintenance'
	},
	LIST_OF_DEVICES: {
		URL: 'api/helpers/list-of-devices',
		KEY: 'list-of-devices'
	},
	DEVICE_DATA: {
		URL: 'api/helpers/device-data',
		KEY: 'device-data'
	},
	GET_REVISIONS: {
		URL: 'api/helpers/available-revisions',
		KEY: 'available-revisions'
	}
};
