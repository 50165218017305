import { useAxios } from '@/features/axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_ROUTES } from '@/api/routes.ts';
import {
	AvalilableRevisionsResponse,
	DeviceModelRequest,
	DeviceModelResponse,
	DeviceModelsTableCollection,
	DeviceModelsTableSortBy
} from '@/types.ts';

export const useDeviceModels = (page: number, perPage?: number, sortBy?: DeviceModelsTableSortBy) => {
	const axios = useAxios();

	return useQuery({
		queryKey: [API_ROUTES.DEVICE_MODELS.KEY, page, perPage, sortBy],

		queryFn: async () => {
			const params = {
				sortBy: {} as DeviceModelsTableSortBy,
				page: page,
				per_page: perPage
			};

			if (sortBy?.title) {
				params.sortBy.title = sortBy.title;
			}

			return (
				await axios.get<DeviceModelsTableCollection>(`${API_ROUTES.DEVICE_MODELS.URL}`, {
					params
				})
			).data;
		}
	});
};

export const useAddDeviceModel = () => {
	const axios = useAxios();

	return useMutation({
		mutationFn: async (deviceModel: DeviceModelRequest) => {
			return (await axios.post<DeviceModelResponse>(API_ROUTES.DEVICE_MODELS.URL, deviceModel)).data;
		}
	});
};

export const useGetDeviceModel = (id: number) => {
	const axios = useAxios();
	return useQuery({
		queryKey: [API_ROUTES.DEVICE_MODELS.KEY, id],
		queryFn: async () => {
			return (await axios.get<DeviceModelResponse>(`${API_ROUTES.DEVICE_MODELS.URL}/${id}`)).data;
		}
	});
};

export const useUpdateDeviceModel = (id: number) => {
	const axios = useAxios();
	return useMutation({
		mutationFn: async (deviceModel: DeviceModelRequest) => {
			return (await axios.put<DeviceModelResponse>(`${API_ROUTES.DEVICE_MODELS.URL}/${id}`, deviceModel)).data;
		}
	});
};

export const useDeleteDeviceModel = () => {
	const axios = useAxios();
	const client = useQueryClient();
	return useMutation({
		mutationFn: async (id: number) => {
			return (await axios.delete<DeviceModelResponse>(`${API_ROUTES.DEVICE_MODELS.URL}/${id}`)).data;
		},
		onSuccess: async () => {
			await client.invalidateQueries({ queryKey: [API_ROUTES.DEVICE_MODELS.KEY] });
		}
	});
};

export const useGetRevisions = () => {
	const axios = useAxios();

	return useQuery({
		queryKey: [API_ROUTES.GET_REVISIONS.KEY],
		queryFn: async () =>
			(await axios.get<AvalilableRevisionsResponse>(API_ROUTES.GET_REVISIONS.URL)).data
	});
};
