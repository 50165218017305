import React, { useEffect } from 'react';
import { Form, Button, Card, Select, Flex, Checkbox, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeviceEntityClass, NotificationInfoForm, NotificationInfoRequest } from '@/types.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessageApiContext } from '@/features/message-api/context';
import { ROUTES } from '@/features/router';
import axios from 'axios';
import { setFormServerErrors } from '@/utils';
import { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import { useNotificationInfo, useUpdateNotificationInfo } from '@/features/notification_info/hooks/api.ts';
import { useAdaptiveContext } from '@/features/adaptive/context/Context.ts';

export type NotificationsSettingsFormProps = {
    entity_class: DeviceEntityClass;
    afterSuccessRoute: string;
};

export const NotificationsSettingsForm: React.FC<NotificationsSettingsFormProps> = ({ entity_class, afterSuccessRoute }) => {
    const { t } = useTranslation('devices');
    const { id } = useParams();
    const [form] = Form.useForm();
    const { data, isLoading, isSuccess } = useNotificationInfo(parseInt(id!), entity_class);
    const { mutateAsync, isPending } = useUpdateNotificationInfo(parseInt(id!));
    const { messageApi } = useMessageApiContext();
    const navigate = useNavigate();
    const { isTabletOrMobile } = useAdaptiveContext();
    const sendNotifications = Form.useWatch("send_notifications", form);

    useEffect(() => {
        if (isSuccess) {
            form.setFieldsValue({
                email: data.data.fields?.EMAIL,
                phone: data.data.fields?.PHONE,
                sendSms: data.data.fields?.SEND_SMS,
                send_notifications: data.data.fields?.SEND_NOTIFICATION,
                notification_interval: data.data.fields?.NOTIFICATION_INTERVAL,
            });
        }
    }, [isSuccess, data, form]);

    const onSubmit = async (values: NotificationInfoForm) => {
        try {
            const params: NotificationInfoRequest = {
                entity_class: entity_class,
                fields: {
                    EMAIL: values.email,
                    PHONE: values.phone,
                    SEND_SMS: values.sendSms,
                    SEND_NOTIFICATION: values.send_notifications,
                    NOTIFICATION_INTERVAL: values.notification_interval
                },
            };
            await mutateAsync(params);
            messageApi.open({
                type: 'success',
                content: t('common:message_success'),
            });
            navigate(afterSuccessRoute);
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: t('common:message_error'),
            });

            if (axios.isAxiosError(e)) {
                const errors = e.response!.data.errors;
                setFormServerErrors(form, errors);
            }
        }
    };

    const copyToClipBoard = async () => {
        await navigator.clipboard.writeText(data?.data.telegram_uri ? data?.data.telegram_uri : '');
    };

    return (
        <Card title={t('notification_settings')} loading={isLoading}>
            <Form className="grid grid-flow-row gap-y-[32px]" layout="vertical" form={form} onFinish={onSubmit} requiredMark={false}>
                <Form.Item
                    name="email"
                    label={t('email')}
                    rules={[
                        {
                            message: t('rule_type_email'),
                            validator(_, values) {
                                const emailRegex = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;
                                const invalidInputs = values.filter((value: string) => !value.match(emailRegex));
                                if (invalidInputs.length === 0) {
                                    return Promise.resolve();
                                } else if (invalidInputs.length === 1) {
                                    return Promise.reject();
                                }
                            },
                        },
                    ]}
                    className="max-w-[440px] mb-0"
                >
                    <Select mode="tags" size="middle" showSearch={false} fieldNames={{ value: 'email' }} style={{ width: '100%' }} />
                </Form.Item>

                <Flex gap={12} className="flex-col justify-start md:items-end md:flex-row">
                    <Form.Item name="phone" label={t('phone')} className="max-w-[440px] w-full mb-0">
                        <Select
                            mode="tags"
                            size="middle"
                            showSearch={false}
                            fieldNames={{ label: 'phone', value: 'phone' }}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item name="sendSms" valuePropName="checked" className="mb-0">
                        <Checkbox>{t('sms_notifications')}</Checkbox>
                    </Form.Item>
                </Flex>

                <Flex gap={12} className="flex-col items-start">
                    <Form.Item name="send_notifications" valuePropName="checked" className="max-w-[440px] w-full mb-0">
                        <Checkbox>{t('send_notifications')}</Checkbox>
                    </Form.Item>
                    {sendNotifications && <Form.Item name="notification_interval"
                                                     initialValue={15}
                                                     label={t('notification_interval')}
                                                     className="max-w-[440px] w-full mb-0"
                    >
                        <Input placeholder={t('notification_interval')} />
                    </Form.Item>}
                </Flex>

                <div className="max-w-[440px]">
                    {t('telegram_bot')}
                    <div className="flex justify-between mt-2 items-center">
                        <a className="underline" target="_blank" href={data?.data.telegram_uri}>
                            {data?.data.telegram_uri}
                            <LinkOutlined className="ml-2.5" />
                        </a>
                        <Button
                            className="!flex"
                            type="primary"
                            loading={isPending}
                            icon={<CopyOutlined className="mt-1" />}
                            onClick={() => copyToClipBoard()}
                        >
                            {!isTabletOrMobile && t('copy')}
                        </Button>
                    </div>
                </div>

                <div>
                    <Button type="primary" htmlType="submit" loading={isPending}>
                        {t('common:submit')}
                    </Button>
                    <Button
                        onClick={() => {
                            navigate(`${ROUTES.DEVICE_ACCIDENTS}/${id}`);
                        }}
                        htmlType="button"
                        className="ml-2"
                    >
                        {t('common:cancel')}
                    </Button>
                </div>
            </Form>
        </Card>
    );
};
